@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./mixin.scss";

@layer utilities {
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
  }
}

html,
body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-weight: 500;

    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    overflow-x: hidden;
    // overflow-y: scroll;
    font-family: "CircularStd";
}

.btn {
    border-radius: 40px;
    border: solid black 1.8px;
    height: 60px;
    width: 200px;
    font-size: 1.1rem;
    background-color: white;
    cursor: pointer;
    font-weight: 600;
    @include Flex();

    @media (max-width: 800px) {
        height: 55px;
        width: 170px;
        font-size: 1rem;
    }

    @media (max-width: 500px) {
        height: 40px;
        width: 130px;
        font-size: 0.9rem;
    }
}

.ReactCollapse--collapse {
    transition: height 400ms;
}

.wa-chat-box-brand {
    padding: 12px;
}

.wa-chat-box-poweredby {
    display: none !important;
}